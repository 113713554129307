import './App.css';

import btImage from './images/bt-banner.jpg'
import sjeImage from './images/sje-banner.jpg'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Container, Typography,Box, Card, CardMedia, CardContent, CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

function App() {

  const openInNewTab = (url: string) => {
    var win = window.open(url, '_blank');
    if(win)
      win.focus();
  }

  return (
    <Container>
        <Typography variant="h1">Welcome!</Typography>
        <Typography variant='body1'>You have hit the landing page of the joint parishes of Blessed Trinity and St. John Evangelist! If you would like to visit a parish's website, please select a link from below.</Typography>
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <CardActionArea onClick={() => openInNewTab("https://www.blessedtrinitysheboyganfalls.org/")}>
              <Card>
                <CardMedia component="img" image={btImage} height={140}/>
                <CardContent>
                  <Typography variant='h2'>Blessed Trinity</Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid xs={12} md={6}>
            <CardActionArea onClick={() => openInNewTab("https://www.stjohnkohler.org/")}>
              <Card>
                <CardMedia component="img" image={sjeImage} height={140}/>
                <CardContent>
                  <Typography variant='h2'>St. John Evangelist</Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
        </Grid>
    </Container>

  );
}

export default App;
